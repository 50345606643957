



















































































































































































































import mixins from 'vue-typed-mixins'
import FlexibleBookingMonthlyUICalendar from '@/calendesk/sections/section/shared/mixins/FlexibleBookingMonthlyUICalendar'
import EmployeePicker from '@/calendesk/sections/section/shared/components/EmployeePicker.vue'
import BookingTimePicker from '@/components/BookingTimePicker.vue'
import CTimeZonesSelect from '@/components/CTimeZonesSelect.vue'
import CCalendarService from '@/components/CCalendarService.vue'
import CLocationSelect from '@/components/CLocationSelect.vue'

export default mixins(FlexibleBookingMonthlyUICalendar).extend({
  name: 'FlexibleBooking2',
  components: { CLocationSelect, CCalendarService, CTimeZonesSelect, BookingTimePicker, EmployeePicker },
  methods: {
    getHeight (): string {
      if (this.$refs.calendarSection &&
        (this.$refs.calendarSection as any).clientHeight > 400) {
        return `${(this.$refs.calendarSection as any).clientHeight}px`
      }

      return '530px'
    }
  }
})
